import { useState, useEffect } from "react"

export function useGeoJsonFetcher(url: string | undefined, preloadedGeoJsonStr = "") {
  const [geoJsonStr, setGeoJsonStr] = useState(preloadedGeoJsonStr)
  useEffect(() => {
    if (!url) return
    fetch(`${url}?1`).then(res => res.text()).then((data) => {
      setGeoJsonStr(data)
    }).catch(console.error)
  }, [url])
  return geoJsonStr
}