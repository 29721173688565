import React, { useContext, useEffect } from "react"
import { MapContext } from "./map"
import { FillLayerBtn } from "./fill-layer.style"
import { LayerContext } from "./layer-manager"
import { useGeoJsonFetcher } from "./geojson-fetcher"

const DEFAULT_OPACITY = 0.6

interface FillLayerProps {
  id: string
  geoJsonStr?: string
  url?: string
  color?: string
  isHighlighted?: boolean
  hasPattern?: boolean
  opacity?: number
  identifier?: string
  isActive?: boolean
  label?: string
}

export const FillLayer = ({
  id,
  geoJsonStr: _geoJsonStr,
  url,
  color,
  isHighlighted,
  hasPattern,
  opacity,
  identifier,
}: FillLayerProps) => {
  const geoJsonStr = useGeoJsonFetcher(url, _geoJsonStr)
  const map = useContext(MapContext)
  useSource(map, identifier, geoJsonStr, id)
  useFill(map, identifier, color, hasPattern, opacity)
  useBorders(map, identifier, isHighlighted)
  return null
}

export const FillLayerWithBtn = (props: FillLayerProps) => {
  const [currLayers, setCurrLayers] = useContext(LayerContext)
  useEffect(() => {
    if (props.isActive) setCurrLayers((cl) => [...cl, { ...props }])
  }, [props.isActive, setCurrLayers])

  // TODO! 
  const isActive = !!currLayers.find(l => l.label === props.label)
  const setActive = () => setCurrLayers(cl => [...cl.filter(l => l.id !== props.id), { ...props }])

  return (
    <>
      <FillLayerBtn
        onClick={setActive}
        color={props.color}
        isActive={isActive}
        className="map-fill-layer-button"
      >
        {props.label || props.identifier}
      </FillLayerBtn>
      <FillLayer {...props} opacity={isActive ? 1 : 0} />
    </>
  )
}

function patchGeoJson(geoJson, layerId) {
  return {
    ...geoJson,
    __currId: layerId,
  }
}

export function useSource(map, id, geoJsonStr, layerId) {
  useEffect(() => {
    if (!map) return
    if (!geoJsonStr) return
    const geoJson = JSON.parse(geoJsonStr || "{}")
    // if (!map.isStyleLoaded()) return
    // console.log("ADD SOURCE", id, geoJson)

    const data = {
      type: "geojson",
      data: patchGeoJson(geoJson, layerId),
    }

    if (map.getSource(id)) {
      map.getSource(id).setData(patchGeoJson(geoJson, layerId))
    } else map.addSource(id, data)

    /* return () => {
      map.removeLayer(id)
      map.removeLayer(`${id}_borders`) // TODO
      map.removeSource(id)
    } */
  }, [map, id, geoJsonStr, layerId])
}

function useFill(map, id, color, hasPattern, opacity) {
  useEffect(() => {
    if (!map) return
    if (!map.getSource(id)) return

    if (map.getLayer(id)) map.removeLayer(id)

    map.addLayer({
      id: id,
      type: "fill",
      source: id,
      layout: {},
      paint: {
        "fill-color": color,
        "fill-opacity": typeof opacity === "number" ? opacity : DEFAULT_OPACITY,
      },
    })
    if (hasPattern) {
      map.addLayer({
        id: `${id}_pattern`,
        type: "fill",
        source: id,
        layout: {},
        paint: {
          "fill-opacity": typeof opacity === "number" ? opacity : DEFAULT_OPACITY,
          "fill-pattern": "stripes1", // "garden-11" // svg needs to be uploaded to mapbox studio
        },
      })
    }
    return () => {
      if (map.getLayer(id)) map.removeLayer(id)
      if (map.getLayer(`${id}_pattern`)) map.removeLayer(`${id}_pattern`)
    }
  }, [map, id, color, hasPattern, opacity])
}

function useBorders(map, id, isHighlighted) {
  useEffect(() => {
    if (!map) return
    if (!map.getSource(id)) return

    // TODO => move highlight to own hook
    const borderColor = isHighlighted
      ? "rgba(255, 0, 0, 0.6)"
      : "rgba(0, 0, 0, 0)" // 0.4
    const borderWidth = isHighlighted ? 3 : 1

    const borderLayerId = `${id}_borders`
    if (map.getLayer(borderLayerId)) return
    map.addLayer({
      id: borderLayerId,
      type: "line",
      source: id,
      paint: {
        "line-color": borderColor,
        "line-width": borderWidth,
      },
    })
    return () => {
      if (map.getLayer(borderLayerId)) map.removeLayer(borderLayerId)
    }
  }, [map, id, isHighlighted])
}

/*
    map.on("mouseenter", id, e => {
      console.log(e)
      if (typeof onMouseEnter !== "function") return
      onMouseEnter(e.features[0].properties)
    })
    map.on("mouseleave", id, e) */
